import {WinPowerShellTest} from '../../../../../generated/graphql';
import {Button, CardHeader, TextField} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {V1IntervalSelect} from '../V1IntervalSelect';

interface WinPowerShellTestViewProps {
  data: Array<WinPowerShellTest>,
  onUpdate: (value: WinPowerShellTest[]) => void
}

export function WinPowerShellTestView({data, onUpdate}: WinPowerShellTestViewProps) {
  return (
    <>
      {/*  Add new test */}
      <Button onClick={() => {
        onUpdate([...data, {
          interval: 3600,
          script: '',
          fieldName: 'Field Name',
          priority: 1000,
        }]);
      }}>Add new test
      </Button>

      {/*  Test Rows */}
      <Grid container spacing={2}>
        {data.map((v, i) => {
          return (<Grid key={i} xs={12}>
            <WinPowerShellTestRow value={v}
              onUpdate={(newValue) => {
                const newPrev = data.slice();
                newPrev[i] = newValue;
                onUpdate(newPrev);
              }}
              onDelete={() => {
                const newPrev = data.filter((_, index) => index !== i);
                // console.log({v, newPrev});
                onUpdate(newPrev);
              }}
            />
          </Grid>);
        })}
      </Grid>
    </>
  );
}


interface WinPowerShellTestRowProps {
  value: WinPowerShellTest,
  onUpdate: (value: WinPowerShellTest) => void
  onDelete: () => void
}


export function WinPowerShellTestRow({value, onUpdate, onDelete}: WinPowerShellTestRowProps) {
  const [interval, setInterval] = useState(value.interval);
  const [script, setScript] = useState(value.script);
  const [fieldName, setFieldName] = useState(value.fieldName);
  const [priority, setPriority] = useState(value.priority);

  useEffect(() => {
    setInterval(value.interval);
    setScript(value.script);
    setFieldName(value.fieldName);
    setPriority(value.priority);
  }, [value]);

  useEffect(() => {
    onUpdate({interval, script, fieldName, priority});
  }, [interval, script, fieldName, priority]);

  return (<Card variant={'outlined'}>
    <CardHeader action={<Button onClick={onDelete}>Delete</Button>}/>
    <CardContent>
      <Grid container>
        <Grid xs={3}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <TextField label={'Field Name'} value={fieldName}
              onChange={(e) => {
                if (e.target.value === '') {
                  return;
                }
                setFieldName(e.target.value);
              }}/>
            <V1IntervalSelect value={interval} onChange={setInterval}/>
            <TextField label={'Priority'} value={priority} type={'number'}
              onChange={(e) => {
                if (parseInt(e.target.value) <= 0) {
                  return;
                }
                setPriority(parseInt(e.target.value));
              }}/>
          </Stack>
        </Grid>
        <Grid xs={9}>
          <TextField
            fullWidth
            multiline
            label={'Script'}
            value={script}
            onChange={(e) => {
              setScript(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>);
}