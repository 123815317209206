import {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import {StringStack} from '../../../../../components/common/StringStack';
import Grid from '@mui/material/Unstable_Grid2';
import CardContent from '@mui/material/CardContent';
import {Button, CardHeader, TextField, Typography} from '@mui/material';
import Stack from '@mui/material/Stack';
import {WinServicesTest} from '../../../../../generated/graphql';
import {V1IntervalSelect} from '../V1IntervalSelect';


interface WinServicesTestViewProps {
  value?: WinServicesTest | null,
  onUpdate: (value: WinServicesTest) => void
}

export function WinServicesTestView({value, onUpdate}: WinServicesTestViewProps) {
  const [serviceNames, setServiceNames] = useState(value?.serviceNames || []);
  const [interval, setInterval] = useState(value?.interval || 3600);
  const [priority, setPriority] = useState(value?.priority || 1000);

  const reset = () => {
    setServiceNames([]);
    setInterval(3600);
    setPriority(1000);
  };

  useEffect(() => {
    if (!value) {
      reset();
      return;
    }
    setServiceNames(value.serviceNames);
    setPriority(value.priority);
    setInterval(value.interval);
  }, [value]);

  useEffect(() => {
    onUpdate({
      serviceNames: serviceNames,
      interval: interval,
      priority: priority,
    });
  }, [
    serviceNames,
    interval,
    priority,
  ]);

  return (<Card variant={'outlined'}>
    <CardHeader action={<Button onClick={reset}>Delete</Button>}/>
    <CardContent>
      <Grid container>
        <Grid xs={3}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <V1IntervalSelect value={interval} onChange={setInterval}/>
            <TextField label={'Priority'} value={priority} type={'number'}
              onChange={(e) => {
                if (parseInt(e.target.value) <= 0) {
                  return;
                }
                setPriority(parseInt(e.target.value));
              }}/>
          </Stack>
        </Grid>
        <Grid xs={3}>
          <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Service Names</Typography>
          <StringStack items={serviceNames} onUpdate={setServiceNames}/>
        </Grid>
      </Grid>
    </CardContent>
  </Card>);
}

