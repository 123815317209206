import {WebsocketTest} from '../../../../generated/graphql';
import {GridColDef} from '@mui/x-data-grid';
import {ArrayCrudView} from './ArrayCrudView';

interface WebsocketTestsProps {
  value: Array<WebsocketTest>,
  onUpdate: (value: (((prevState: Array<WebsocketTest>) => Array<WebsocketTest>) | Array<WebsocketTest>)) => void
}

const sharedOptions: Partial<GridColDef> = {
  editable: true,
  type: 'number',
  headerAlign: 'center',
  align: 'center',
  flex: 1,
};


const columns: GridColDef[] = [
  {
    field: 'target',
    headerName: 'Target',
    editable: true,
    flex: 1,
    align: 'left',
  },

  {
    field: 'backoff',
    headerName: 'Backoff',
    ...sharedOptions,
  },
  {
    field: 'interval',
    headerName: 'Interval',
    type: 'singleSelect',
    valueOptions: [120, 300, 1800, 3600, 14400, 28800],
    editable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    ...sharedOptions,
  }
];

export function WebsocketTestsView({value, onUpdate}: WebsocketTestsProps) {
  return (<ArrayCrudView columns={columns} rows={value} onUpdate={onUpdate}
    newValue={(): WebsocketTest => ({
      target: 'example.com:5555',
      maxBackoff: 300,
      backoff: 5,
      interval: 120,
      priority: 1000,
    })}
  />);
}