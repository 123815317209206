import {useId} from 'react';
import {FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select} from '@mui/material';

interface V1IntervalRadioProps {
  value: number,
  onChange: (interval: number) => void,
}

export function V1IntervalRadio({value, onChange}: V1IntervalRadioProps) {
  const id = useId();
  const labelId = `${id}-label`;

  return (<FormControl>
    <FormLabel id={labelId}>Interval</FormLabel>
    <RadioGroup row
      id={id}
      aria-labelledby={labelId}
      defaultValue={value}
      onChange={(e) => {
        onChange(parseInt(e.target.value));
      }}>
      <FormControlLabel value={120} control={<Radio/>} label="2 min"/>
      <FormControlLabel value={300} control={<Radio/>} label="5 min"/>
      <FormControlLabel value={1800} control={<Radio/>} label="30 min"/>
      <FormControlLabel value={3600} control={<Radio/>} label="1 hour"/>
      <FormControlLabel value={14400} control={<Radio/>} label="4 hours"/>
      <FormControlLabel value={28800} control={<Radio/>} label="8 hours"/>
    </RadioGroup>
  </FormControl>);
}

interface V1IntervalSelectProps {
  value: number,
  onChange: (interval: number) => void,
}

export function V1IntervalSelect({value, onChange}: V1IntervalSelectProps) {
  const id = useId();
  const labelId = `${id}-label`;
  return (<FormControl>
    <InputLabel id={labelId}>Interval</InputLabel>
    <Select
      id={id}
      labelId={labelId}
      label={'Interval'}
      value={value}
      onChange={(e) => {
        onChange(parseInt(e.target.value.toString()));
      }}>
      <MenuItem value={120}>2 min</MenuItem>
      <MenuItem value={300}>5 min</MenuItem>
      <MenuItem value={1800}>30 min</MenuItem>
      <MenuItem value={3600}>1 hour</MenuItem>
      <MenuItem value={14400}>4 hours</MenuItem>
      <MenuItem value={28800}>8 hours</MenuItem>
    </Select>
  </FormControl>);
}