import {MtrTest} from '../../../../generated/graphql';
import {GridColDef} from '@mui/x-data-grid';
import {ArrayCrudView} from './ArrayCrudView';

interface TestsProps {
  value: Array<MtrTest>;
  onUpdate: (value: (((prevState: Array<MtrTest>) => Array<MtrTest>) | Array<MtrTest>)) => void;
}

const sharedOptions: Partial<GridColDef> = {
  editable: true,
  type: 'number',
  headerAlign: 'center',
  align: 'center',
  flex: 1,
};

const columns: GridColDef[] = [
  {
    field: 'target',
    headerName: 'Target',
    editable: true,
    flex: 1,
    align: 'left',
  },
  {
    field: 'redirects',
    headerName: 'Redirects',
    ...sharedOptions,
  },
  {
    field: 'timeout',
    headerName: 'Timeout',
    ...sharedOptions,
  },
  {
    field: 'interval',
    headerName: 'Interval',
    type: 'singleSelect',
    valueOptions: [120, 300, 1800, 3600, 14400, 28800],
    editable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    ...sharedOptions,
  }
];

export function MtrTestsView({value, onUpdate}: TestsProps) {
  return (<ArrayCrudView columns={columns} rows={value} onUpdate={onUpdate}
    newValue={(): MtrTest => ({
      target: 'example.com',
      redirects: 5,
      timeout: 10,
      interval: 120,
      priority: 1000,
      args: [],
    })}
  />);
}

