import {
  BasicTest,
  InputRttTest,
  SpeedTest,
  TestTarget,
  TestTargetUpdateInput,
  useUpdateTestTargetMutation
} from '../../../generated/graphql';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {BasicTargetView} from './test-targets/BasicTargetView';
import React, {useEffect, useState} from 'react';
import {ProxyTargetView} from './test-targets/ProxyTargetView';
import {ValidateRequiredString} from '../../../components/common/form/ValidTextField';
import {BusinessUnitDropdown} from '../employee/BusinessUnitDropdown';
import {DivisionDropdown} from '../employee/DivisionDropdown';
import {LocationDropdown} from '../employee/LocationDropdown';
import {HttpTestsView} from './test-targets/HttpTestsView';
import {HttpV2TestsView} from './test-targets/HttpV2TestsView';
import {MtrTestsView} from './test-targets/MtrTestView';
import {WifiV2TargetView} from './test-targets/WifiV2TargetView';
import {RttTestsView} from './test-targets/RttTestView';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import {WinPerfCounterTestView} from './test-targets/windows/WinPerfCounterTestView';
import {WinPowerShellTestView} from './test-targets/windows/WinPowerShellTestView';
import {WinServicesTestView} from './test-targets/windows/WinServiceTestView';
import Stack from '@mui/material/Stack';
import {useAppDispatch} from '../../../hooks/app';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {DNSTestsView} from './test-targets/DNSTestsView';
import {WebsocketTestsView} from './test-targets/WebsocketTestsView';

interface TestTargetViewProps {
  target: TestTarget,
  onUpdate: () => void,
}


export function TestTargetView({target, onUpdate}: TestTargetViewProps) {
  const dispatch = useAppDispatch();
  const [businessUnitId, setBusinessUnitId] = useState(target.businessUnit?.id || '*');
  const [divisionId, setDivisionId] = useState(target.division?.id || '*');
  const [locationId, setLocationId] = useState(target.location?.id || '*');
  const [region, setRegion] = useState(target.region || '*');
  const [tecIdentity, setTecIdentity] = useState(target.tecIdentity);
  const [employeeId, setEmployeeId] = useState(target.employeeId);
  const [vdi, setVdi] = useState(target.VDI || false);
  const [osData, setOsData] = useState(target.OSDataTest);
  const [memory, setMemory] = useState(target.MemoryTest);
  const [speed, setSpeed] = useState(target.SpeedTest);
  const [proxy, setProxy] = useState(target.Proxy);
  const [httpTests, setHttpTests] = useState(target.HTTPTests);
  const [httpV2Tests, setHttpV2Tests] = useState(target.HTTPTestsV2);
  const [mtrTests, setMtrTests] = useState(target.MTRTests);
  const [rttTests, setRttTests] = useState(target.RTTTests);
  const [wifiTest, setWifiTest] = useState(target.WifiTest);
  const [winPerfCounterTests, setWinPerfCounterTests] = useState(target.WinPerfCounterTests);
  const [winPowerShellTests, setWinPowerShellTests] = useState(target.WinPowershellTests);
  const [winServicesTest, setWinServicesTest] = useState(target.WinServicesTest);
  const [websocketTests, setWebsocketTests] = useState(target.WebsocketTests);
  const [dnsTests, setDnsTests] = useState(target.DNSQueries);

  function isEmptyOrStar(value: string): boolean {
    return value === '' || value === '*';
  }

  function GetTestTargetInput(): TestTargetUpdateInput {
    return {
      divisionId: isEmptyOrStar(divisionId) ? undefined : divisionId,
      locationId: isEmptyOrStar(locationId) ? undefined : locationId,
      businessUnitId: isEmptyOrStar(businessUnitId) ? undefined : businessUnitId,
      region: isEmptyOrStar(region) ? undefined : region,
      tecIdentity: isEmptyOrStar(tecIdentity) ? undefined : tecIdentity,
      employeeId: isEmptyOrStar(employeeId) ? undefined : employeeId,
      VDI: vdi,
      OSDataTest: osData,
      MemoryTest: memory,
      SpeedTest: speed,
      Proxy: proxy,
      HTTPTests: httpTests,
      HTTPTestsV2: httpV2Tests,
      MTRTests: mtrTests,
      RTTTests: rttTests.map((r): InputRttTest => {
        return {
          ...r,
        } as InputRttTest;
      }),
      WebsocketTests: websocketTests,
      DNSQueries: dnsTests,
      WifiTest: wifiTest,
      WinPerfCounterTests: winPerfCounterTests,
      WinPowershellTests: winPowerShellTests,
      WinServicesTest: winServicesTest?.serviceNames.length === 0 ? undefined : winServicesTest,
    };
  }

  const [testTargetUpdate, setTestTargetUpdate] = useState<TestTargetUpdateInput>(GetTestTargetInput());

  useEffect(() => {
    setBusinessUnitId(target.businessUnit?.id || '*');
    setDivisionId(target.division?.id || '*');
    setLocationId(target.location?.id || '*');
    setRegion(target.region || '*');
    setTecIdentity(target.tecIdentity || '*');
    setEmployeeId(target.employeeId || '*');
    setVdi(target.VDI || false);
    setOsData(target.OSDataTest);
    setMemory(target.MemoryTest);
    setSpeed(target.SpeedTest);
    setProxy(target.Proxy);
    setHttpTests(target.HTTPTests);
    setHttpV2Tests(target.HTTPTestsV2);
    setMtrTests(target.MTRTests);
    setRttTests(target.RTTTests);
    setWifiTest(target.WifiTest);
    setWinPerfCounterTests(target.WinPerfCounterTests);
    setWinPowerShellTests(target.WinPowershellTests);
    setWinServicesTest(target.WinServicesTest);
    setWebsocketTests(target.WebsocketTests);
    setDnsTests(target.DNSQueries);
  }, [target]);

  useEffect(() => {
    setTestTargetUpdate(GetTestTargetInput());
  }, [
    businessUnitId, divisionId, locationId, region,
    tecIdentity, employeeId, osData, memory, speed, proxy,
    httpTests, httpV2Tests, mtrTests, rttTests, wifiTest,
    winPerfCounterTests, winPowerShellTests, winServicesTest,
    websocketTests, dnsTests, vdi
  ]);

  const validateMatcher = (v: string): boolean => {
    if (v === '' || v === '*') {
      return true;
    }
    return ValidateRequiredString(v);
  };

  const [updateTestTargetMutation] = useUpdateTestTargetMutation();

  function updateTestTarget() {
    updateTestTargetMutation({
      variables: {
        id: target.id,
        input: testTargetUpdate,
      }
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'TestTarget updated successfully',
          severity: 'success',
          autoHideDuration: 3000,
        }));
      })
      .catch((error) => {
        dispatch(addMessage({
          id: uuidv4(),
          message: error.message,
          severity: 'error',
          autoHideDuration: 3000,
        }));
      })
      .finally(() => {
        onUpdate();
      });
  }

  return (
    <Paper elevation={4} sx={{padding: 2}}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h5" component="h2" gutterBottom>
              Matchers
            </Typography>
            <Button variant="contained" onClick={updateTestTarget}>Save</Button>
          </Stack>
        </Grid>
        <Grid xs={4} sx={{minWidth: 200}}>
          <BusinessUnitDropdown fullWidth label="Business Unit"
            emptyValue={'*'} emptyLabel={'All'}
            id={'test-target-business-unit-dropdown'}
            validate={validateMatcher} value={businessUnitId}
            setValue={setBusinessUnitId}/>
        </Grid>
        <Grid xs={4} sx={{minWidth: 200}}>
          <DivisionDropdown required fullWidth label="Division"
            id={'test-target-division-dropdown'}
            validate={validateMatcher} value={divisionId}
            emptyValue={'*'} emptyLabel={'All'}
            businessUnitId={businessUnitId} setValue={setDivisionId}/>
        </Grid>
        <Grid xs={4} sx={{minWidth: 200}}>
          <LocationDropdown required fullWidth label="Location"
            emptyValue={'*'} emptyLabel={'All'}
            id={'test-target-location'}
            validate={validateMatcher} value={locationId}
            setValue={setLocationId}/>
        </Grid>
        <Grid xs={4} sx={{minWidth: 200}}>
          <TextField fullWidth value={region} label="Region" onChange={
            (e) => {
              const value = e.target.value;
              if (value !== '') {
                setRegion(e.target.value);
              } else {
                setRegion('');
              }
            }
          }/>
        </Grid>
        <Grid xs={4} sx={{minWidth: 200}}>
          <TextField fullWidth value={tecIdentity} label="TEC Identity" onChange={
            (e) => {
              const value = e.target.value;
              if (value !== '') {
                setTecIdentity(e.target.value);
              } else {
                setTecIdentity(undefined);
              }
            }
          } error={tecIdentity && tecIdentity !== '*' && isNaN(Number(tecIdentity))}/>
        </Grid>
        <Grid xs={4} sx={{minWidth: 200}}>
          <TextField fullWidth value={employeeId} label="Employee ID" onChange={
            (e) => {
              const value = e.target.value;
              if (value !== '') {
                setEmployeeId(e.target.value);
              } else {
                setEmployeeId(undefined);
              }
            }
          } error={employeeId && employeeId !== '*' && isNaN(Number(employeeId))}/>
        </Grid>
        <Grid xs={3} sx={{minWidth: 200}}>
          <FormControlLabel control={<Checkbox checked={vdi} onChange={() => {
            setVdi(!vdi);
          }}/>} label="VDI"/>
        </Grid>
      </Grid>
      <Typography variant="h5" component="h2" gutterBottom marginTop={1}>
        Targets
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1-content" id="panel1-header">
          General
        </AccordionSummary>
        <AccordionDetails>
          <BasicTargetView value={target.OSDataTest} title={'OS Data'} defaultInterval={1800}
            onUpdate={(v) => {
              setOsData(v as BasicTest | null);
            }}/>
          <BasicTargetView value={target.MemoryTest} title={'Memory'} defaultInterval={300}
            onUpdate={(v) => {
              setMemory(v as BasicTest | null);
            }}/>
          <BasicTargetView value={target.SpeedTest} title={'Speed'} defaultInterval={3600}
            onUpdate={(v) => {
              if (v === null) {
                setSpeed(null);
                return;
              }

              setSpeed({
                interval: v.interval,
                priority: v.priority,
                testJitter: '2m',
              } as SpeedTest);
            }}/>
          <ProxyTargetView value={target.Proxy} onUpdate={setProxy}/>
          <WifiV2TargetView defaultCrontab={'*/2 * * * *'} value={wifiTest} onUpdate={setWifiTest}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content" id="panel2-header">
          HTTP
        </AccordionSummary>
        <AccordionDetails>
          <HttpTestsView value={httpTests} onUpdate={setHttpTests}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel3-content" id="panel3-header">
          HTTP <Chip sx={{marginLeft: 1}} label={'v2'} size={'small'}/>
        </AccordionSummary>
        <AccordionDetails>
          <HttpV2TestsView value={httpV2Tests} onUpdate={setHttpV2Tests}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel4-content" id="panel4-header">
          MTR
        </AccordionSummary>
        <AccordionDetails>
          <MtrTestsView value={mtrTests} onUpdate={setMtrTests}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel5-content" id="panel5-header">
          RTT
        </AccordionSummary>
        <AccordionDetails>
          <RttTestsView value={rttTests} onUpdate={setRttTests}/>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel6-content" id="panel6-header">
          Websocket
        </AccordionSummary>
        <AccordionDetails>
          <WebsocketTestsView value={websocketTests} onUpdate={setWebsocketTests}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel7-content" id="panel7-header">
          DNS
        </AccordionSummary>
        <AccordionDetails>
          <DNSTestsView value={dnsTests} onUpdate={setDnsTests}/>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel7-content" id="panel7-header">
          Performance Counters <MicrosoftIcon sx={{marginLeft: 1}}/>
        </AccordionSummary>
        <AccordionDetails>
          <WinPerfCounterTestView data={winPerfCounterTests} onUpdate={setWinPerfCounterTests}/>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel7-content" id="panel7-header">
          PowerShell <MicrosoftIcon sx={{marginLeft: 1}}/>
        </AccordionSummary>
        <AccordionDetails>
          <WinPowerShellTestView data={winPowerShellTests} onUpdate={setWinPowerShellTests}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel7-content" id="panel7-header">
          Services <MicrosoftIcon sx={{marginLeft: 1}}/>
        </AccordionSummary>
        <AccordionDetails>
          <WinServicesTestView value={winServicesTest} onUpdate={setWinServicesTest}/>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
