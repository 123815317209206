import {BusinessUnitList} from './business-unit/BusinessUnitList';
import {SyntheticEvent, useState} from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {TabList} from '@mui/lab';
import {Box} from '@mui/material';
import {LocationList} from './location/LocationTable';
import {EmployeeList} from './employee/EmployeeTable';
import {TestTargetsList} from './test-targets/TestTargetsList';
import {ComputerList} from './computer/ComputerTable';

export function WorkforceManage() {
  const [value, setValue] = useState('1');
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{width: '100%', typography: 'body1'}}>
      <TabContext value={value}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Business Units" value="1"/>
            <Tab label="Locations" value="2"/>
            <Tab label="Employees" value="3"/>
            <Tab label="Test Targets" value="4"/>
            <Tab label="Computers" value="5"/>
          </TabList>
        </Box>
        <TabPanel value="1"><BusinessUnitList/></TabPanel>
        <TabPanel value="2"><LocationList/></TabPanel>
        <TabPanel value="3"><EmployeeList/></TabPanel>
        <TabPanel value="4"><TestTargetsList/></TabPanel>
        <TabPanel value="5"><ComputerList/></TabPanel>
      </TabContext>
    </Box>
  );
}