import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/app';
import {
  resetStatus,
  selectWorkforceImport,
  setCurrentStep,
} from '../../../features/workforceImport/workforceImportSlice';
import Button from '@mui/material/Button';
import {LoadingButton} from '@mui/lab';
import {WorkforceImportStatusCard} from '../StatusCard';
import useWorkforceImport from '../../../features/workforceImport/useWorkforceImport';

export function WorkforceImportReview() {
  const dispatch = useAppDispatch();
  const workforceImport = useAppSelector(selectWorkforceImport);
  const {importData} = useWorkforceImport();
  const [importStarted, setImportStarted] = useState<boolean>(false);
  const [importFinished, setImportFinished] = useState<boolean>(false);

  useEffect(() => {
    if (!importFinished && workforceImport.status.location.done && workforceImport.status.employee.done) {
      setImportFinished(true);
    }
  }, [workforceImport.status]);

  return (
    <>
      <Box marginTop={2} display={'flex'} justifyContent={'center'} alignContent={'center'} flexDirection={'column'}>
        <WorkforceImportStatusCard started={importStarted}/>
      </Box>
      {importStarted ? <Box marginTop={2} sx={{display: 'flex', justifyContent: 'end'}}>
        <Button variant={'contained'} color={'warning'} disabled={true}>
          Back
        </Button>

        <LoadingButton variant={'contained'} color={'primary'} sx={{marginLeft: 2}}
          loading={!importFinished} disabled={importFinished}>
          Import
        </LoadingButton>
      </Box> : <Box marginTop={2} sx={{display: 'flex', justifyContent: 'end'}}>
        <Button variant={'contained'} color={'warning'}
          onClick={() => {
            dispatch(resetStatus());
            dispatch(setCurrentStep('validate'));
          }}>
          Back
        </Button>

        <Button variant={'contained'} color={'primary'} sx={{marginLeft: 2}}
          onClick={() => {
            setImportStarted(true);
            importData().then().catch(console.error);
          }}
        >
          Import
        </Button>
      </Box>}
    </>
  );
}