import {GridColDef, GridRowModel} from '@mui/x-data-grid';
import {
  Computer,
  ComputerConnection,
  GetComputersQuery,
  GetComputersQueryVariables,
  useGetComputersLazyQuery,
  useUpdateComputerMutation
} from '../../../generated/graphql';
import {GridRowSelectionModel} from '@mui/x-data-grid/models/gridRowSelectionModel';
import {ApolloError, LazyQueryHookExecOptions, QueryResult} from '@apollo/client';
import {useAppDispatch} from '../../../hooks/app';
import {useEffect, useState} from 'react';
import ApiErrorAlert from '../../../components/common/ApiErrorAlert';
import {FullFeaturedCrudGrid} from '../FullFeaturedCrudGrid';
import {Typography} from '@mui/material';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';

const sharedOptions = {
  flex: 1,
  editable: true,
};

const columns: GridColDef[] = [
  {
    field: 'identity',
    headerName: 'Identity',
    minWidth: 320,
    flex: 1,
  },
  {
    field: 'hostname',
    headerName: 'Hostname',
    flex: 1,
  },
  {
    field: 'domain',
    headerName: 'Domain',
    flex: 1,
  },
  {
    field: 'fqdn',
    headerName: 'FQDN',
    flex: 1,
  },
  {
    field: 'vdi',
    headerName: 'VDI',
    flex: 1,
    type: 'boolean',
  },
  {
    field: 'collector',
    headerName: 'Collector',
    type: 'boolean',
    ...sharedOptions,
  },
  {
    field: 'arch',
    headerName: 'Arch',
    flex: 1,
  },
  {
    field: 'os',
    headerName: 'OS',
    flex: 1,
  },
];

interface ComputerTableProps {
  handleRowSelection: (rowSelectionModel: GridRowSelectionModel, selected: Computer[]) => void,
}

export function fetchComputers(getComputers: (options?: Partial<LazyQueryHookExecOptions<GetComputersQuery, GetComputersQueryVariables>>) => Promise<QueryResult<GetComputersQuery, GetComputersQueryVariables>>, pageSize: number, setValues: (value: (((prevState: (Computer[] | undefined)) => (Computer[] | undefined)) | Computer[] | undefined)) => void) {
  const queryResult = async (after: string | null): Promise<ComputerConnection | undefined> => {
    try {
      const data = await getComputers({
        variables: {
          first: pageSize,
          after: after,
        }
      });
      return data.data?.computers as ComputerConnection;
    } catch (error) {
      // console.log('error', error);
    }
  };

  const fetchData = async () => {
    const data = await queryResult(null);
    let nodes = data?.nodes as Computer[];
    let pageInfo = data?.pageInfo;

    while (pageInfo && pageInfo?.hasNextPage) {
      const newData = await queryResult(pageInfo.endCursor);

      if (newData) {
        nodes = nodes.concat(newData.nodes as Computer[]);
        pageInfo = newData.pageInfo;
      } else {
        pageInfo = undefined;
      }
    }
    setValues(nodes);
  };
  return fetchData;
}

export function ComputerTable(props: ComputerTableProps) {
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<Computer[] | undefined>(undefined);
  const [pageSize, setPageSize] = useState(10);
  const [getComputers, {loading, error}] = useGetComputersLazyQuery({
    fetchPolicy: 'network-only',
    // nextFetchPolicy: 'cache-first',
  });
  const [updateComputerMutation] = useUpdateComputerMutation();
  const fetchData = fetchComputers(getComputers, pageSize, setValues);

  // Gather all data for the table
  useEffect(() => {
    fetchData().then();
  }, [pageSize]);

  if (error) {
    return <ApiErrorAlert title="Error loading computers" possibleErrors={[
      error.message,
    ]}/>;
  }


  if (values === undefined) {
    return <>Loading...</>;
  }

  const processRowUpdate = (newRow: GridRowModel): Promise<GridRowModel> => {
    // console.log('processRowUpdate', {newRow});
    return new Promise((resolve, reject) => {
      updateComputerMutation({
        variables: {
          id: newRow.id,
          input: {
            vdi: newRow.vdi as boolean,
            collector: newRow.collector as boolean,
          }
        }
      })
        .then((value) => {
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Computer updated',
            severity: 'success',
            autoHideDuration: 3000,
          }));

          fetchData()
            .then()
            .finally(() => {
              resolve(value.data?.updateComputer as Computer);
            });

        })
        .catch((error: ApolloError) => {
          if (error.message.includes('violates unique constraint')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'Computer name and external id must be unique',
              severity: 'error',
              autoHideDuration: 3000,
            }));
            return;
          }

          if (error.message.includes('no changes')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'No changes',
              severity: 'warning',
              autoHideDuration: 3000,
            }));
            return;
          }

          console.error(error);
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Error updating computer',
            severity: 'error',
            autoHideDuration: 3000,
          }));
          reject(error);
        });
    });
  };


  return (
    <FullFeaturedCrudGrid
      loading={loading}
      rows={values}
      columns={columns}
      handleRowSelection={props.handleRowSelection}
      processRowUpdate={processRowUpdate}
      handleDeleteClick={() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Delete not implemented',
          severity: 'warning',
          autoHideDuration: 2000,
        }));
      }}
      slots={{
        // toolbar: EditToolbar,
      }}
      onPaginationModelChange={(pagination) => {
        if (pagination.pageSize !== pageSize) {
          setPageSize(pagination.pageSize);
        }
      }}
    />
  );
}

export function ComputerList() {
  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        Computers
      </Typography>
      <ComputerTable handleRowSelection={() => {
      }}/>
    </>
  );
}