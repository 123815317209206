import {RttTest} from '../../../../generated/graphql';
import {GridColDef} from '@mui/x-data-grid';
import {ArrayCrudView} from './ArrayCrudView';

interface TestsProps {
  value: Array<RttTest>;
  onUpdate: (value: (((prevState: Array<RttTest>) => Array<RttTest>) | Array<RttTest>)) => void;
}

const sharedOptions: Partial<GridColDef> = {
  editable: true,
  type: 'number',
  headerAlign: 'center',
  align: 'center',
  flex: 1,
};

const columns: GridColDef[] = [
  {
    field: 'remoteAddr',
    headerName: 'Remote Address',
    editable: true,
    flex: 1,
    align: 'left',
  },
  {
    field: 'remotePort',
    headerName: 'Remote Port',
    ...sharedOptions,
  },
  {
    field: 'localPort',
    headerName: 'Local Port',
    ...sharedOptions,
  },
  {
    field: 'interval',
    headerName: 'Interval',
    type: 'singleSelect',
    valueOptions: [120, 300, 1800, 3600, 14400, 28800],
    editable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    ...sharedOptions,
  }
];

export function RttTestsView({value, onUpdate}: TestsProps) {
  return (<ArrayCrudView columns={columns} rows={value} onUpdate={onUpdate}
    newValue={(): RttTest => ({
      remoteAddr: '5.5.5.5',
      dscp: '0xb8',
      df: 'default',
      remotePort: 5061,
      localPort: 5061,
      interval: 300,
      priority: 1000,
    } as RttTest)}
  />);
}

