import {useAppSelector} from '../../hooks/app';
import {selectWorkforceImport, StatusDetail} from '../../features/workforceImport/workforceImportSlice';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Box, CardHeader, CircularProgress, Typography} from '@mui/material';
import {TaskAlt} from '@mui/icons-material';
import {LinearProgressWithLabel} from '../../components/common/LinearProgressWithLabel';
import ApiErrorAlert from '../../components/common/ApiErrorAlert';
import CopyToClipboardButton from '../../components/common/CopyToClipboardButton';
import React from 'react';

export function WorkforceImportStatusCard({started}: { started?: boolean }) {
  const importState = useAppSelector(selectWorkforceImport);

  // Function to calculate the percentage of completion
  const calculateCompletion = (status: StatusDetail) => {
    const total = status.missing.length;
    if (status.done) {
      return 100;
    }
    return total === 0 ? 0 : Math.round((status.complete.length / status.missing.length) * 100);
  };

  return (
    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center'}}>
      {Object.keys(importState.status).map((key) => {
        // @ts-ignore
        const categoryStatus = importState.status[key];
        return (
          <Card key={key} sx={{minWidth: 275, flex: '1 0 auto'}}>
            <CardHeader
              sx={{pb: 0}}
              subheader={key.charAt(0).toUpperCase() + key.slice(1)}
              action={started ?
                (categoryStatus.done ? <TaskAlt color={'success'}/> :
                  <CircularProgress size={24}/>)
                : (<TaskAlt color={'disabled'}/>)}
            />
            <CardContent>
              <Box sx={{width: '100%'}}>
                <LinearProgressWithLabel variant={'determinate'} value={calculateCompletion(categoryStatus)}/>
              </Box>

              <Typography variant="body2">
                Complete: {categoryStatus.complete.length}
              </Typography>
              <Typography variant="body2">
                Existing: {categoryStatus.existing.length}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Missing: {categoryStatus.missing.length}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Failed: {categoryStatus.failed.length}
              </Typography>
              {categoryStatus.failed.length > 0 && <Box marginTop={1}>
                <CopyToClipboardButton
                  message={'Copy failed items to clipboard'}
                  data={categoryStatus.failed}/>
              </Box>}
              {categoryStatus.errors.length > 0 &&
                <Box marginTop={1}><ApiErrorAlert possibleErrors={categoryStatus.errors}/></Box>}
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
}