import {useAppDispatch} from '../../../hooks/app';
import React, {useEffect, useState} from 'react';
import {TestTargetCreateInput, useCreateTestTargetMutation} from '../../../generated/graphql';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {Box, Button, Checkbox, FormControlLabel, Modal, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {BusinessUnitDropdown} from '../employee/BusinessUnitDropdown';
import {DivisionDropdown} from '../employee/DivisionDropdown';
import {LocationDropdown} from '../employee/LocationDropdown';
import {ValidateRequiredString} from '../../../components/common/form/ValidTextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

interface NewTestTargetsModalProps {
  open: boolean,
  handleClose: () => void,
}

export function NewTestTargetsModal({open, handleClose}: NewTestTargetsModalProps) {
  const dispatch = useAppDispatch();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [testTarget, setTestTarget] = useState<TestTargetCreateInput>({
    OSDataTest: null,
    MemoryTest: null,
    SpeedTest: null,
    Proxy: null,
    HTTPTests: [],
    HTTPTestsV2: [],
    MTRTests: [],
    RTTTests: [],
    WifiTest: null,
    WinPerfCounterTests: [],
    WinPowershellTests: [],
    WinServicesTest: null,
  });

  const [createTestTargetMutation] = useCreateTestTargetMutation();

  const clearForm = () => {
    setTestTarget({
      OSDataTest: null,
      MemoryTest: null,
      SpeedTest: null,
      Proxy: null,
      HTTPTests: [],
      HTTPTestsV2: [],
      MTRTests: [],
      RTTTests: [],
      WifiTest: null,
      WinPerfCounterTests: [],
      WinPowershellTests: [],
      WinServicesTest: null,
    });
  };

  const handleSubmit = () => {
    // Handle the submission logic here
    // For example: send this data to an API or update the state in a parent component
    console.log({testTarget});

    createTestTargetMutation({
      variables: {
        input: testTarget,
      },
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'TestTarget created successfully',
          severity: 'success',
          autoHideDuration: 3000,
        }));
        handleClose(); // Close the modal after submission
      })
      .catch((error) => {
        dispatch(addMessage({
          id: uuidv4(),
          message: error.message,
          severity: 'error',
          autoHideDuration: 3000,
        }));
      });
  };

  return (
    <Modal open={open} onClose={() => {
      clearForm();
      handleClose();
    }}
    aria-labelledby="create-new-test-target"
    aria-describedby="modal-modal-description"
    >
      <Box sx={style} component={'form'}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create New Test Target
        </Typography>
        <TestTargetForm testTarget={testTarget} onUpdate={(testTarget, valid) => {
          setTestTarget(testTarget);
          setFormValid(valid);
        }}/>
        <Button variant="contained" onClick={handleSubmit} disabled={!formValid}>
          Create
        </Button>
      </Box>
    </Modal>);
}

interface TestTargetFormProps {
  testTarget: TestTargetCreateInput,
  onUpdate: (testTarget: TestTargetCreateInput, valid: boolean) => void
}

function TestTargetForm({testTarget, onUpdate}: TestTargetFormProps) {
  const [divisionId, setDivisionId] = useState<string>(testTarget.divisionId || '');
  const [locationId, setLocationId] = useState<string>(testTarget.locationId || '');
  const [businessUnitId, setBusinessUnitId] = useState(testTarget.businessUnitId || '');
  const [region, setRegion] = useState(testTarget.region || '');
  const [tecId, setTecId] = useState(testTarget.tecIdentity);
  const [employeeId, setEmployeeId] = useState(testTarget.employeeId);
  const [vdi, setVdi] = useState<boolean>(false);

  const validateForm = (): boolean => {
    if (employeeId && isNaN(Number(employeeId))) {
      return false;
    }

    if (tecId && isNaN(Number(tecId))) {
      return false;
    }

    return !(!validateMatcher(divisionId) || !validateMatcher(locationId) || !validateMatcher(businessUnitId) || !validateMatcher(region));
  };

  function isEmptyOrStar(value: string): boolean {
    return value === '' || value === '*';
  }

  const testTargetValues = (): TestTargetCreateInput => ({
    divisionId: isEmptyOrStar(divisionId) ? undefined : divisionId,
    locationId: isEmptyOrStar(locationId) ? undefined : locationId,
    businessUnitId: isEmptyOrStar(businessUnitId) ? undefined : businessUnitId,
    region: isEmptyOrStar(region) ? undefined : region,
    tecIdentity: tecId,
    employeeId: employeeId,
    VDI: vdi,
  });

  useEffect(() => {
    onUpdate(testTargetValues(), validateForm());
  }, [
    divisionId, locationId,
    businessUnitId, region,
    tecId, employeeId,
    vdi,
  ]);

  const validateMatcher = (v: string): boolean => {
    if (!v || v === '' || v === '*') {
      return true;
    }
    return ValidateRequiredString(v);
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={3} sx={{minWidth: 200}}>
        <BusinessUnitDropdown fullWidth label="Business Unit"
          emptyValue={'*'} emptyLabel={'All'}
          id={'test-target-business-unit-dropdown'}
          validate={validateMatcher} value={businessUnitId || ''}
          setValue={setBusinessUnitId}/>
      </Grid>
      <Grid xs={3} sx={{minWidth: 200}}>
        <DivisionDropdown required fullWidth label="Division"
          id={'test-target-division-dropdown'}
          validate={validateMatcher} value={divisionId || ''}
          emptyValue={'*'} emptyLabel={'All'}
          businessUnitId={businessUnitId} setValue={setDivisionId}/>
      </Grid>
      <Grid xs={3} sx={{minWidth: 200}}>
        <LocationDropdown required fullWidth label="Location"
          emptyValue={'*'} emptyLabel={'All'}
          id={'test-target-location'}
          validate={validateMatcher} value={locationId || ''}
          setValue={setLocationId}/>
      </Grid>
      <Grid xs={3} sx={{minWidth: 200}}>
        <TextField fullWidth value={region} label="Region" onChange={
          (e) => {
            const value = e.target.value;
            if (value !== '') {
              setRegion(e.target.value);
            } else {
              setRegion('');
            }
          }
        }/>
      </Grid>
      <Grid xs={3} sx={{minWidth: 200}}>
        <TextField fullWidth value={tecId} label="TEC Identity" onChange={
          (e) => {
            const value = e.target.value;
            if (value !== '') {
              setTecId(e.target.value);
            } else {
              setTecId(undefined);
            }
          }
        } error={tecId && isNaN(Number(tecId))}/>
      </Grid>
      <Grid xs={3} sx={{minWidth: 200}}>
        <TextField fullWidth value={employeeId} label="Employee ID" onChange={
          (e) => {
            const value = e.target.value;
            if (value !== '') {
              setEmployeeId(e.target.value);
            } else {
              setEmployeeId(undefined);
            }
          }
        } error={employeeId && isNaN(Number(employeeId))}/>
      </Grid>
      <Grid xs={3} sx={{minWidth: 200}}>
        <FormControlLabel control={<Checkbox checked={vdi} onChange={() => {
          setVdi(!vdi);
        }}/>} label="VDI"/>
      </Grid>
    </Grid>);
}