import Grid from '@mui/material/Unstable_Grid2';
import {
  GetVdiConfigDocument,
  useGetVdiConfigQuery,
  useUpdateVdiConfigMutation,
  VdiConfig
} from '../../generated/graphql';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Container,
  TextField
} from '@mui/material';
import ApiErrorAlert from '../../components/common/ApiErrorAlert';
import React, {useEffect, useState} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {addMessage} from '../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {useAppDispatch} from '../../hooks/app';
import {graphqlClient} from '../../config';

export function SettingsPage() {
  const dispatch = useAppDispatch();
  const {loading, error, data} = useGetVdiConfigQuery();
  const [vdiConfig, setVdiConfig] = useState<VdiConfig>(data?.vdiConfig || {});

  const [updateVdiConfig] = useUpdateVdiConfigMutation();
  const saveVdiConfig = () => {
    updateVdiConfig({
      variables: {
        CIDR: vdiConfig.CIDR,
        hostnamePattern: vdiConfig.hostnamePattern,
        ipPattern: vdiConfig.ipPattern
      }
    }).then(() => {
      dispatch(addMessage({
        id: uuidv4(),
        message: 'TestTarget updated successfully',
        severity: 'success',
        autoHideDuration: 3000,
      }));
    })
      .catch((error) => {
        dispatch(addMessage({
          id: uuidv4(),
          message: error.message,
          severity: 'error',
          autoHideDuration: 3000,
        }));
      })
      .finally(() => {
        graphqlClient.refetchQueries({include: [GetVdiConfigDocument]});
      });
  };

  useEffect(() => {
    // console.log('SettingsPage', data);
    if (data?.vdiConfig) {
      setVdiConfig(data.vdiConfig);
    }
  }, [data, data?.vdiConfig]);

  return (
    <Container sx={{marginTop: 2}}>
      <Accordion expanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1-content" id="panel1-header">
          VDI Config
        </AccordionSummary>
        <AccordionDetails>
          {loading && <CircularProgress/>}
          {error && <ApiErrorAlert title={'Error Fetching Config'} possibleErrors={[error.message]}/>}
          <VDIConfig vdiConfig={data?.vdiConfig || {}} onChange={setVdiConfig}/>
        </AccordionDetails>
      </Accordion>
      <Grid container>
        <Grid xs={1}>
          <Button onClick={saveVdiConfig} variant="contained">Save</Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export function VDIConfig({vdiConfig, onChange}: {
  vdiConfig: VdiConfig,
  onChange: (value: (((prevState: VdiConfig) => VdiConfig) | VdiConfig)) => void
}) {
  const [cidr, setCidr] = useState(vdiConfig.CIDR);
  const [hostnamePattern, setHostnamePattern] = useState(vdiConfig.hostnamePattern);
  const [ipPattern, setIpPattern] = useState(vdiConfig.ipPattern);

  useEffect(() => {
    // console.log('VDIConfig', vdiConfig);
    setCidr(vdiConfig.CIDR);
    setHostnamePattern(vdiConfig.hostnamePattern);
    setIpPattern(vdiConfig.ipPattern);
  }, [vdiConfig]);

  useEffect(() => {
    onChange({
      ...vdiConfig,
      CIDR: cidr,
      hostnamePattern,
      ipPattern
    });
  }, [cidr, hostnamePattern, ipPattern]);

  return (
    <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
      <Grid xs={3} sx={{minWidth: 200}}>
        <TextField fullWidth value={hostnamePattern || ''} label="Hostname Regex"
          helperText={'Enter a regex pattern'}
          onChange={(e) => {
            const value = e.target.value;
            if (value !== '') {
              setHostnamePattern(e.target.value);
            } else {
              setHostnamePattern(undefined);
            }
          }}/>
      </Grid>
      <Grid xs={3} sx={{minWidth: 200}}>
        <TextField fullWidth value={ipPattern || ''} label="IP Regex"
          helperText={'Enter a regex pattern'}
          onChange={(e) => {
            const value = e.target.value;
            if (value !== '') {
              setIpPattern(e.target.value);
            } else {
              setIpPattern(undefined);
            }
          }}/>
      </Grid>
      <Grid xs={3} sx={{minWidth: 200}}>
        <TextField fullWidth value={cidr || ''} label="CIDR"
          helperText={'Enter a CIDR range'}
          onChange={(e) => {
            const value = e.target.value;
            if (value !== '') {
              setCidr(e.target.value);
            } else {
              setCidr(undefined);
            }
          }}/>
      </Grid>
    </Grid>
  );
}