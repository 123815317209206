import {GridRowSelectionModel} from '@mui/x-data-grid/models/gridRowSelectionModel';
import {
  TestTarget,
  TestTargetConnection,
  useDeleteTestTargetMutation,
  useGetTestTargetsLazyQuery,
} from '../../../generated/graphql';
import {useEffect, useState} from 'react';
import ApiErrorAlert from '../../../components/common/ApiErrorAlert';
import {FullFeaturedCrudGrid} from '../FullFeaturedCrudGrid';
import {GridColDef, GridRowId, GridRowModel, GridToolbarContainer} from '@mui/x-data-grid';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import {Button, Chip, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import {NewTestTargetsModal} from './NewTestTargetsModal';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {useAppDispatch} from '../../../hooks/app';

const sharedOptions: Partial<GridColDef> = {
  flex: 1,
  align: 'center',
  headerAlign: 'center',
  minWidth: 50,
};
const testTargetColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
  },
  {
    field: 'businessUnitId',
    valueGetter: (params) => {
      if (params.row.businessUnitId === null) {
        return '*';
      }
      return (params.row.businessUnit as { name: string }).name;
    },
    headerName: 'Business Unit',
    // minWidth: 200,
    editable: false,
    ...sharedOptions,
  },
  {
    field: 'divisionId',
    valueGetter: (params) => {
      if (params.row.divisionId === null) {
        return '*';
      }

      return (params.row.division as { name: string }).name;
    },
    headerName: 'Division',
    // minWidth: 200,
    editable: false,
    ...sharedOptions,
  },
  {
    field: 'locationId',
    valueGetter: (params) => {
      if (params.row.locationId === null) {
        return '*';
      }
      return (params.row.location as { name: string }).name;
    },
    headerName: 'Location',
    // minWidth: 200,
    editable: false,
    ...sharedOptions,
  },
  {
    field: 'region',
    valueGetter: (params) => {
      if (params.row.region === null) {
        return '*';
      }
      return params.row.region;
    },
    headerName: 'Region',
    // minWidth: 200,
    editable: false,
    ...sharedOptions,
  },
  {
    field: 'tecIdentity',
    headerName: 'TEC ID',
    // sortable: false,
    editable: false,
    valueGetter: (params) => {
      if (params.row.tecIdentity === null) {
        return '*';
      }
      return params.row.tecIdentity;
    },
    ...sharedOptions,
  },
  {
    field: 'employeeId',
    headerName: 'Employee ID',
    // sortable: false,
    editable: false,
    valueGetter: (params) => {
      if (params.row.employeeId === null) {
        return '*';
      }
      return params.row.employeeId;
    },
    ...sharedOptions,
  },
  {
    field: 'VDI',
    ...sharedOptions,
    type: 'boolean',
  },
  {
    field: 'MemoryTest',
    ...sharedOptions,
    headerName: 'Memory',
    sortable: false,
    renderCell: (params) => {
      return <NullCellChip value={params.row.MemoryTest}/>;
    },
    minWidth: 90,
  },
  {
    field: 'OSDataTest',
    ...sharedOptions,
    headerName: 'OS Data',
    sortable: false,
    renderCell: (params) => {
      return <NullCellChip value={params.row.OSDataTest}/>;
    },
    minWidth: 90,
  },
  {
    field: 'SpeedTest',
    ...sharedOptions,
    headerName: 'Speed',
    sortable: false,
    renderCell: (params) => {
      return <NullCellChip value={params.row.SpeedTest}/>;
    }
  },
  {
    field: 'Proxy',
    ...sharedOptions,
    headerName: 'Proxy',
    sortable: false,
    renderCell: (params) => {
      return <NullCellChip value={params.row.Proxy}/>;
    }
  },
  {
    field: 'WifiTest',
    renderHeader: () => {
      return (<>
        <Typography variant={'inherit'} fontWeight={500}>
          Wifi
        </Typography>
        <Chip sx={{marginLeft: '5px'}} label={'v2'} size={'small'}/>
      </>);
    },
    sortable: false,
    renderCell: (params) => {
      return <NullCellChip value={params.row.WifiTest}/>;
    },
    ...sharedOptions,
    minWidth: 90,
  },
  {
    field: 'HTTPTests',
    headerName: 'HTTP',
    sortable: false,
    renderCell: (params) => {
      return <ListCellChip value={params.row.HTTPTests}/>;
    },
    ...sharedOptions,
  },
  {
    field: 'HTTPTestsV2',
    renderHeader: () => {
      return (<>
        <Typography variant={'inherit'} fontWeight={500}>
          HTTP
        </Typography>
        <Chip sx={{marginLeft: '5px'}} label={'v2'} size={'small'}/>
      </>);
    },
    sortable: false,
    renderCell: (params) => {
      return <ListCellChip value={params.row.HTTPTestsV2}/>;
    },
    ...sharedOptions,
    minWidth: 90,
  },
  {
    field: 'MTRTests',
    headerName: 'MTR',
    sortable: false,
    renderCell: (params) => {
      return <ListCellChip value={params.row.MTRTests}/>;
    },
    ...sharedOptions,
  },
  {
    field: 'RTTTests',
    headerName: 'RTT',
    sortable: false,
    renderCell: (params) => {
      return <ListCellChip value={params.row.RTTTests}/>;
    },
    ...sharedOptions,
  },
  {
    field: 'WebsocketTests',
    headerName: 'Websocket',
    sortable: false,
    renderCell: (params) => {
      return <ListCellChip value={params.row.WebsocketTests}/>;
    },
  },
  {
    field: 'DNSQueries',
    headerName: 'DNS',
    sortable: false,
    renderCell: (params) => {
      return <ListCellChip value={params.row.DNSQueries}/>;
    },
  },
  {
    field: 'WinPerfCounterTests',
    renderHeader: () => {
      return (<>
        <Typography variant={'inherit'} fontWeight={500}>Perf Counters</Typography>
        <MicrosoftIcon sx={{marginLeft: '5px'}} fontSize={'small'} alignmentBaseline={'central'}/>
      </>);
    },
    sortable: false,
    renderCell: (params) => {
      return <ListCellChip value={params.row.WinPerfCounterTests}/>;
    },
    ...sharedOptions,
    minWidth: 140,
  },
  {
    field: 'WinPowershellTests',
    renderHeader: () => {
      return (<>
        <Typography variant={'inherit'} fontWeight={500}>Powershell</Typography>
        <MicrosoftIcon sx={{marginLeft: '5px'}} fontSize={'small'} alignmentBaseline={'central'}/>
      </>);
    },
    sortable: false,
    renderCell: (params) => {
      return <ListCellChip value={params.row.WinPowershellTests}/>;
    },
    ...sharedOptions,
    minWidth: 130,
  },
  {
    field: 'WinServicesTest',
    renderHeader: () => {
      return (<>
        <Typography variant={'inherit'} fontWeight={500}>Services</Typography>
        <MicrosoftIcon sx={{marginLeft: '5px'}} fontSize={'small'} alignmentBaseline={'central'}/>
      </>);
    },
    sortable: false,
    renderCell: (params) => {
      return <ListCellChip value={params.row.WinServicesTest?.serviceNames || []}/>;
    },
    ...sharedOptions,
    minWidth: 100,
  },
];

function NullCellChip(props: { value?: never }) {
  if (props.value === null) {
    return <CheckCircleOutlineIcon color={'disabled'}/>;
  }
  return <CheckCircleIcon color={'primary'}/>;
}

function ListCellChip(props: { value: never[] }) {
  if (!props.value || props.value.length === 0) {
    return <Chip label={0} variant="filled" disabled/>;
  }
  return <Chip label={props.value.length} variant="filled" color={'primary'}/>;
}

interface TestTargetsTableProps {
  handleRowSelection: (rowSelectionModel: GridRowSelectionModel, selected: TestTarget[]) => void,
  reload?: string,
}

export function TestTargetsTable(props: TestTargetsTableProps) {
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<TestTarget[] | undefined>(undefined);
  const [pageSize, setPageSize] = useState(10);
  const [getTestTargets, {loading, error}] = useGetTestTargetsLazyQuery({
    fetchPolicy: 'network-only',
    // nextFetchPolicy: 'cache-first',
  });
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [deleteTestTarget] = useDeleteTestTargetMutation();

  const queryResult = async (after: string | null): Promise<TestTargetConnection | undefined> => {
    try {
      const data = await getTestTargets({
        variables: {
          first: pageSize,
          after: after,
        }
      });
      return data.data?.testTargets as TestTargetConnection;
    } catch (error) {
      // setError(error as ApolloError);
    }
  };

  const fetchData = async () => {
    const data = await queryResult(null);
    let nodes = data?.nodes as TestTarget[];
    let pageInfo = data?.pageInfo;

    while (pageInfo && pageInfo?.hasNextPage) {
      const newData = await queryResult(pageInfo.endCursor);

      if (newData) {
        nodes = nodes.concat(newData.nodes as TestTarget[]);
        pageInfo = newData.pageInfo;
      } else {
        pageInfo = undefined;
      }
    }
    setValues(nodes);
  };

  const selectedRows: TestTarget[] = values?.filter((x) => selected.includes(x.id)) || [];

  // Gather all data for the table
  useEffect(() => {
    fetchData().then();
  }, [pageSize, props.reload]);

  // Handle the row selection
  useEffect(() => {
    props.handleRowSelection(selected, selectedRows);
  }, [selected]);

  if (error) {
    return <ApiErrorAlert title="Error loading test targets" possibleErrors={[
      error.message,
    ]}/>;
  }

  if (values === undefined) {
    return <>Loading...</>;
  }

  const processRowUpdate = (newRow: GridRowModel): Promise<GridRowModel> => {
    // console.log('processRowUpdate', {newRow});
    return new Promise((resolve) => {
      resolve(newRow);
    });
  };

  const handleDeleteClick = (id: GridRowId) => {
    deleteTestTarget({
      variables: {
        id: id as string,
      },
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'TestTarget deleted',
          severity: 'success',
          autoHideDuration: 3000,
        }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Error deleting test target',
          severity: 'error',
          autoHideDuration: 3000,
        }));
      })
      .finally(() => {
        fetchData().then();
      });
  };

  function EditToolbar() {
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => {
      setModalOpen(true);
    };
    const handleCloseModal = () => {
      fetchData().then();
      setModalOpen(false);
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon/>} onClick={() => {
          handleOpenModal();
        }}>
          Add record
        </Button>
        <NewTestTargetsModal open={modalOpen} handleClose={handleCloseModal}/>
      </GridToolbarContainer>
    );
  }

  return (
    <FullFeaturedCrudGrid
      loading={loading}
      rows={values}
      columns={testTargetColumns}

      processRowUpdate={processRowUpdate}
      handleDeleteClick={handleDeleteClick}

      handleRowSelection={rowSelectionModel => {
        setSelected(rowSelectionModel);
      }}

      slots={{
        toolbar: EditToolbar,
      }}

      onPaginationModelChange={(pagination) => {
        if (pagination.pageSize !== pageSize) {
          setPageSize(pagination.pageSize);
        }
      }}
      disableEdit
    />
  );
}